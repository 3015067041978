import Head from 'next/head'
import Image from 'next/image'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { Box } from '../components/Box'
import { Button } from '@/components/Button'
import { Section } from '@/components/Section'
import { MobileCodeEditor, DesktopCodeEditor } from '../components/code/Editor'

// capsule transfer product example
import capsuleTransferImage from '@/images/products/capsule-transfer.gif'
// capsule features
import batchImage from '@/images/features/batch.png'
import rerouteImage from '@/images/features/reroute.png'
import secureImage from '@/images/features/secure.png'
import gaslessImage from '@/images/features/gasless-pic.png'
// partners
import partners from '@/images/partnership/partners.png'

import { useEffect, useRef, useState } from 'react'

function Hero() {
  return (
    <section>
      <div className="relative">
        <video
          className="relative z-0 h-screen w-screen object-cover"
          preload="auto"
          playsInline
          autoPlay
          loop
          muted
        >
          <source
            src="https://www-capsule-video-assets.s3.amazonaws.com/hero.mp4"
            type="video/mp4"
          />
          <p>Your browser cannot play the provided video file.</p>
        </video>
        <div className="z-1 container absolute inset-0 pt-20 pl-4 text-white lg:pt-40 lg:pl-8">
          <h1 className="max-w-md font-title text-4xl tracking-tight sm:text-6xl md:max-w-2xl lg:max-w-3xl xl:max-w-6xl xl:text-8xl">
            Packaging & Shipping for Blockchain
          </h1>
          <p className="mt-6 mb-8 max-w-md text-left font-medium leading-8 text-white md:max-w-2xl md:text-xl lg:max-w-3xl lg:text-2xl xl:max-w-6xl">
            Batch, send, & recall secure transfers
          </p>
          <Button
            as="a"
            className="bg-capsule-gradient px-6 py-4 font-medium lg:text-lg"
            href={'https://app.capsulelabs.xyz/'}
            rel="noopener noreferrer"
            target="_blank"
          >
            Launch App
          </Button>
        </div>
      </div>
    </section>
  )
}

function Stats() {
  return (
    <div className="container px-4 lg:px-10">
      <div className="bg-capsule-gradient relative -mt-[7.50rem] grid grid-cols-2 rounded-xl sm:mx-auto sm:-mt-[4rem] sm:grid-cols-4">
        <Box className="items-center justify-center rounded-none py-6">
          <Box.Title
            as="p"
            className="text-xl sm:text-3xl lg:text-4xl xl:text-5xl"
          >
            4K+
          </Box.Title>
          <Box.Description className="text-center text-xs font-medium text-zinc-300 sm:text-sm lg:text-lg">
            Capsules Minted
          </Box.Description>
          <div
            aria-hidden="true"
            className="absolute bottom-0 h-[0.5px] w-1/2 bg-zinc-500 sm:hidden"
          ></div>
        </Box>
        <Box className="items-center justify-center rounded-none py-6">
          <Box.Title
            as="p"
            className="text-xl sm:text-3xl lg:text-4xl xl:text-5xl"
          >
            3K+
          </Box.Title>
          <Box.Description className="text-center text-xs font-medium text-zinc-300 sm:text-sm lg:text-lg">
            Unique Users
          </Box.Description>
          <div
            aria-hidden="true"
            className="absolute bottom-0 h-[0.5px] w-1/2 bg-zinc-500 sm:hidden"
          ></div>
          <div
            aria-hidden="true"
            className="absolute left-0 h-1/2 w-[0.5px] bg-zinc-500"
          ></div>
        </Box>
        <Box className="items-center justify-center rounded-none py-6">
          <Box.Title
            as="p"
            className="text-xl sm:text-3xl lg:text-4xl xl:text-5xl"
          >
            15K+
          </Box.Title>
          <Box.Description className="text-center text-xs font-medium text-zinc-300 sm:text-sm lg:text-lg">
            Capsules Transfered
          </Box.Description>
          <div
            aria-hidden="true"
            className="absolute left-0 hidden h-1/2 w-[0.5px] bg-zinc-500 sm:block"
          ></div>
        </Box>
        <Box className="items-center justify-center py-6">
          <Box.Title
            as="p"
            className="text-xl sm:text-3xl lg:text-4xl xl:text-5xl"
          >
            100K+
          </Box.Title>
          <Box.Description className="text-center text-xs font-medium text-zinc-300 sm:text-sm lg:text-lg">
            Total Value Stored
          </Box.Description>
          <div
            aria-hidden="true"
            className="absolute left-0 h-1/2 w-[0.5px] bg-zinc-500"
          ></div>
        </Box>
      </div>
    </div>
  )
}

function Features() {
  return (
    <Section>
      <div className="mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          <div className="flex flex-col items-center justify-center text-center">
            <div className="flex justify-center items-center h-32 w-32 lg:h-36 lg:w-36 mx-auto">
              <Image
                placeholder="blur"
                className="max-h-full max-w-full"
                src={batchImage}
                alt="Capsule Batch Image"
              />
            </div>
            <Box className="w-full max-w-md">
              <Box.Description className="text-zinc-400 text-md md:text-lg">
                Batch multiple asset transfers into one transfer
              </Box.Description>
            </Box>
          </div>
          <div className="flex flex-col items-center justify-center text-center">
            <div className="flex justify-center items-center h-32 w-32 lg:h-36 lg:w-36 mx-auto">
              <Image
                placeholder="blur"
                className="max-h-full max-w-full"
                src={rerouteImage}
                alt="Capsule Reroute Image"
              />
            </div>
            <Box className="w-full max-w-md text-center">
              <Box.Description className="text-zinc-400 text-md md:text-lg">
                Reroute or recall a transfer after it&apos;s been sent
              </Box.Description>
            </Box>
          </div>
          <div className="flex flex-col items-center justify-center text-center">
            <div className="flex justify-center items-center h-32 w-32 lg:h-36 lg:w-36 mx-auto">
              <Image
                placeholder="blur"
                className="max-h-full max-w-full"
                src={secureImage}
                alt="Capsule Security Image"
              />
            </div>
            <Box className="w-full max-w-md text-center">
              <Box.Description className="text-zinc-400 text-md md:text-lg">
                Add on-chain or off-chain security to any transfer
              </Box.Description>
            </Box>
          </div>
          <div className="flex flex-col items-center justify-center text-center">
            <div className="flex justify-center items-center h-32 w-32 lg:h-36 lg:w-36 mx-auto">
              <Image
                placeholder="blur"
                className="max-h-full max-w-full"
                src={gaslessImage}
                alt="Capsule Gasless Image"
              />
            </div>
            <Box className="w-full max-w-md text-center">
              <Box.Description className="text-zinc-400 text-md md:text-lg">
                Accept transfers on any chain without paying gas
              </Box.Description>
            </Box>
          </div>
        </div>
      </div>
    </Section>
  )
}

function CapsuleTransfer() {
  const texts = [
    'Batch multiple asset transfers into a single, secure transfer.',
    'Reroute or recall a transfer anytime after it has been sent.',
    'Add enhanced security to any transfer to assure delivery.',
    'Accept a transfer on any supported chain without paying gas.',
  ]
  const [currentText, setCurrentText] = useState(texts[0])
  const [key, setKey] = useState(0)

  useEffect(() => {
      let index = 0
      const timer = setInterval(() => {
          index = index + 1 === texts.length ? 0 : index + 1
          setCurrentText(texts[index])
          setKey(prevKey => prevKey + 1)
      }, 4000)
      return () => clearInterval(timer)
  }, [])

  return (
    <Section>
      <Section.Title>Capsule Transfer</Section.Title>
      <Section.Subtitle>
        Send programmable, gasless transfers with enhanced security features.
      </Section.Subtitle>
      <Section.Content>
        <div className="mx-auto space-y-10">
          <div className="flex flex-col-reverse sm:flex-row justify-between sm:space-x-12">
            <Box className="max-w-md lg:mt-20 mb-8 sm:mb-0">
              <div className="text-container">
                <TransitionGroup className="transition-group">
                  <CSSTransition key={key} classNames="fade" timeout={1000}>
                    <Box.Title className="text-sm text-center sm:text-left sm:text-md md:text-xl lg:text-2xl">
                      {currentText}
                    </Box.Title>
                  </CSSTransition>
                </TransitionGroup>
              </div>
              <Box.Cta className="mt-8 lg:mt-16 flex justify-center sm:justify-start">
                <Button
                  as="a"
                  className="bg-capsule-gradient px-4 py-3"
                  href={'https://app.capsulelabs.xyz/'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Launch App
                </Button>
              </Box.Cta>
            </Box>
            <Image
              className="h-auto w-auto md:h-full md:w-1/2 lg:mt-10 mb-8 sm:mb-0"
              src={capsuleTransferImage}
              alt="Capsule Transfer Image"
            />
          </div>
        </div>
      </Section.Content>
      <div className="mt-20 md:mt-40">
        <Features/>
      </div>
    </Section>
  )
}

function Code() {
  return (
    <Section>
      <Section.Title>The Capsule API</Section.Title>
      <Section.Subtitle>
        Integrate Capsule functionality into any project, on or off-chain.
      </Section.Subtitle>
      <Section.MobileContent>
        <MobileCodeEditor />
      </Section.MobileContent>
      <Section.DesktopContent className="xl:px-40">
        <DesktopCodeEditor />
      </Section.DesktopContent>
    </Section>
  )
}

function Partners() {
  return (
    <Section className="pb-20">
      <Section.Title>Our Ecosystem</Section.Title>
      <Section.Subtitle>
        We&apos;ve worked with countless projects online and in person.
      </Section.Subtitle>
      <Section.Content className="flex flex-wrap justify-center md:space-x-4">
        <Image
          placeholder="blur"
          className="h-auto w-auto"
          src={partners}
          alt="Capsule Ecosystem Image"
        />
      </Section.Content>
    </Section>
  )
}

function SendOff() {
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {
        threshold: 0.5,
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  return (
    <div ref={ref} className={`pt-10 pb-60 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <Section>
        <Section.Subtitle>
          Transfer on any blockchain without compromise.
        </Section.Subtitle>
      </Section>
    </div>
  )
}

export default function Index() {
  return (
    <>
      <Head>
        <title>Capsule</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="description"
          content="Transfer on any blockchain without compromise."
        />
      </Head>
      <Hero />
      <Stats />
      <div className="mt-20 space-y-60 px-4 pt-20 pb-20 md:container md:px-10">
        <CapsuleTransfer />
        <Code />
        <Partners />
        <SendOff />
      </div>
    </>
  )
}
