import { RedeemCapsuleIcon } from '../Icons'

const redeemPackage = {
  menuName: 'Redeem Package',
  title: 'Redeem Package',
  description: 'Pick up an package shipped to your address, retrieving all assets in the process.',
  icon: (props: any) => <RedeemCapsuleIcon {...props} />,
  code: `// Solidity ^0.8.0

function myPickup(
  uint256 packageId_,
  string calldata rawPassword_,
  string calldata salt_,
  bool shouldRedeem_
) external {
    // your code here...

    postOffice.pickup(packageId_, rawPassword_, salt_, shouldRedeem_);
}`,
  gitBookLink:
    'https://docs.capsulenft.com/',
}

export default redeemPackage
