import { ShipPackageCapsuleIcon } from '../Icons'

const shipPackage = {
  menuName: 'Ship Package',
  title: 'Ship Package',
  description: 'Ship a package holding any amount of an asset type to any user. Add any amount of security features.',
  icon: (props: any) => <ShipPackageCapsuleIcon {...props} />,
  code: `// Solidity ^0.8.0

function shipMyPackage(
  CapsuleData.CapsuleContent calldata packageContent_,
  PostOffice.SecurityInfo calldata securityInfo_,
  address receiver_
) external returns (uint256) {
    // your code here...

    return postOffice.shipPackage(packageContent_, securityInfo_, receiver_);
}`,
  gitBookLink:
    'https://docs.capsulenft.com/',
}

export default shipPackage
