import clsx from 'clsx'
import { PropsWithChildren, useId } from 'react'

export function Section({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  const id = useId()

  return (
    <section aria-labelledby={id} className={className}>
      {children}
    </section>
  )
}

Section.Title = function SectionTitle({
  as: Component = 'h2',
  children,
  className,
}: PropsWithChildren<{ as?: React.ElementType; className?: string }>) {
  const id = useId()

  return (
    <Component
      id={id}
      className={clsx(
        className,
        'font-druk z-20 text-center font-title text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl xl:text-6xl'
      )}
    >
      {children}
    </Component>
  )
}

Section.Subtitle = function SectionSubtitle({
  as: Component = 'p',
  children,
  className,
}: PropsWithChildren<{ as?: React.ElementType; className?: string }>) {
  return (
    <Component
      className={clsx(
        className,
        'mt-4 text-center font-medium text-zinc-400 lg:text-2xl'
      )}
    >
      {children}
    </Component>
  )
}

Section.Content = function SectionContent({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx(className, 'pt-20')}>{children}</div>
}

Section.MobileContent = function SectionMobileContent({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx(className, 'pt-20 md:hidden')}>{children}</div>
}

Section.DesktopContent = function SectionDesktopContent({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={clsx(className, 'hidden pt-20 md:block')}>{children}</div>
  )
}
