import { ForwardSlashIcon } from './Icons'

export type BreadcrumbProps = {
  path: string[]
}

export default function Breadcrumb({ path }: BreadcrumbProps) {
  return (
    <div className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center">
        {path.map((component, index) => (
          <li key={component}>
            <div className="flex items-center">
              {index > 0 && (
                <ForwardSlashIcon className="h-5 w-5 flex-shrink-0 text-zinc-300" />
              )}
              <span
                className={`text-sm font-medium ${
                  index == path.length - 1 ? 'text-white' : 'text-zinc-400'
                }`}
                aria-current={index == path.length - 1 ? 'page' : undefined}
              >
                {component}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  )
}
