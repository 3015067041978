import { refractor } from 'refractor'
import sol from 'refractor/lang/solidity'
import { toH } from 'hast-to-hyperscript'

import shipPackage from './ShipPackage'
import reroutePackage from './ReroutePackage'
import shipPackages from './ShipPackages'
import cancelPackage from './CancelPackage'
import redeemPackage from './RedeemPackage'

import Image from 'next/image'
import logoQuantstamp from '@/images/security/quantstamp.png'
import clsx from 'clsx'
import { Fragment, useState } from 'react'
import React from 'react'
import { Box } from '../Box'
import { CopiedIcon } from '../Icons'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Breadcrumb from '../Breadcrumb'

refractor.register(sol)

const order = [
  shipPackage,
  //shipPackages,
  cancelPackage,
  reroutePackage,
  redeemPackage,
]

function EditorTop() {
  return (
    <div className="flex h-[55px] flex-row items-center justify-between rounded-t-lg p-4 text-white">
      <div className="flex flex-row items-center justify-start space-x-2">
        <button
          aria-hidden="true"
          className="h-2 w-2 rounded-full bg-zinc-400 shadow-2xl shadow-zinc-200"
        />
        <button
          aria-hidden="true"
          className="h-2 w-2 rounded-full bg-zinc-400 shadow-2xl shadow-zinc-200"
        />
        <button
          aria-hidden="true"
          className="h-2 w-2 rounded-full bg-zinc-400 shadow-2xl shadow-zinc-200"
        />
      </div>
    </div>
  )
}

function EditorBottom() {
  return (
    <a
      className="flex flex-col items-center justify-center space-y-8 pt-12 md:flex-row md:space-x-8 md:space-y-0"
      href="https://certificate.quantstamp.com/full/capsule-nft"
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src={logoQuantstamp}
        alt="Quantstamp Logo"
        width={96}
        placeholder="blur"
      />
      <p className="text-center text-lg text-white">
        Code independently audited by Quantstamp
      </p>
    </a>
  )
}

function CopiedPopup() {
  return (
    <>
      <div className="absolute bottom-[4.5rem] -right-2 w-20 rounded-lg bg-[#22252D] p-1 text-sm text-white">
        Copied!
      </div>
      {/* Tiangle svg */}
      <svg
        className="absolute bottom-16 right-6 rotate-180"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <path d="M8 0L16 8H0L8 0Z" fill="#22252D" />
      </svg>
    </>
  )
}

export function DesktopCodeEditor() {
  const [selection, setSelection] = useState(order[0])
  const [copied, setCopied] = useState(false)

  return (
    <>
      <div className="bg-capsule-gradient rounded-2xl">
        <EditorTop />
        <div className="grid h-full grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6">
          <div>
            <ul
              role="list"
              className="mx-4 space-y-2 border-t border-zinc-700 py-4"
            >
              {order.map((item) => (
                <li key={item.menuName}>
                  <button
                    onClick={() => setSelection(item)}
                    className={clsx(
                      item === selection
                        ? 'bg-capsule-gradient_alpha rounded-lg text-white'
                        : '',
                      'hover:bg-capsule-gradient_alpha p-2 text-left text-zinc-300 hover:rounded-lg',
                      'flex w-full items-center gap-2 text-xs'
                    )}
                  >
                    {item.icon({ className: 'h-6 w-6' })}
                    {item.menuName}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-code-gradient col-span-3 grid grid-cols-3 rounded-br-lg lg:col-span-4 2xl:col-span-5">
            <pre className="text-md relative col-span-2 whitespace-pre-wrap break-all px-4 py-4 text-white">
              <code className="text-[10px]">
                {toH(
                  React.createElement,
                  refractor.highlight(selection.code, 'solidity')
                )}
              </code>
              <button
                aria-label="Copy to clipboard"
                onClick={() => {
                  navigator.clipboard.writeText(selection.code)
                  setCopied(true)
                  setTimeout(() => setCopied(false), 2000)
                }}
                className="absolute bottom-8 right-8 p-2"
              >
                {copied && <CopiedPopup />}
                <CopiedIcon className="h-12 w-12" />
              </button>
            </pre>
            <Box
              as="a"
              href={selection.gitBookLink}
              rel="noopener noreferrer"
              target="_blank"
              className="items-center space-y-2 rounded-none border-l border-zinc-600 px-2 py-8"
            >
              <span className="pb-4">
                {selection.icon({ className: 'h-12 w-12' })}
              </span>
              <Box.Title className="text-center">{selection.title}</Box.Title>
              <Box.Subtitle className="border-y border-zinc-600 py-2 text-sm font-bold">
                {selection.menuName}
              </Box.Subtitle>
              <Box.Description className="w-3/4 text-center text-xs text-zinc-400">
                {selection.description}
              </Box.Description>
              <Box.Cta className="text-capsule-purple">Gitbook</Box.Cta>
            </Box>
          </div>
        </div>
      </div>
      <EditorBottom />
    </>
  )
}

export function MobileCodeEditor() {
  const [selection, setSelection] = useState(order[0])

  return (
    <>
      <div className="space-y-4">
        <Listbox value={selection} onChange={(item) => setSelection(item)}>
          <div className="relative mt-1">
            <Listbox.Button className="bg-capsule-gradient relative w-full cursor-default rounded-lg py-3 pl-3 pr-10 text-left shadow-lg focus:outline-none focus-visible:border-zinc-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-zinc-300 sm:text-sm">
              <Breadcrumb path={['Classic Capsule', selection.menuName]} />
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-zinc-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="bg-capsule-gradient absolute z-40 my-1 max-h-60 w-full overflow-auto rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className="bg-code-gradient">
                  <h4 className="border-b border-zinc-500 py-2 pl-4 font-bold text-white">
                    Classic Capsule
                  </h4>
                  {order.map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 px-8 text-sm ${
                            active
                              ? 'bg-code-gradient text-white'
                              : 'text-zinc-400'
                          }`
                        }
                        value={item}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {item.menuName}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center text-white">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    )
                  })}
                </div>
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        <div className="bg-capsule-gradient rounded-lg">
          <Box
            as="a"
            href={selection.gitBookLink}
            rel="noopener noreferrer"
            target="_blank"
            className="bg-code-gradient items-center space-y-2 rounded-lg px-2 py-8"
          >
            <span className="pb-4">
              {selection.icon({ className: 'h-12 w-12' })}
            </span>
            <Box.Title className="text-center">{selection.title}</Box.Title>
            <Box.Subtitle className="border-y border-zinc-600 py-2 text-sm font-bold">
              {selection.menuName}
            </Box.Subtitle>
            <Box.Description className="w-3/4 text-center text-xs text-zinc-400">
              {selection.description}
            </Box.Description>
            <Box.Cta className="text-capsule-purple">Gitbook</Box.Cta>
          </Box>
        </div>
        <div className="bg-capsule-gradient rounded-lg">
          <EditorTop />
          <pre className="bg-code-gradient lg:text-md overflow-x-auto whitespace-pre-wrap break-all rounded-b-lg px-4 py-4 text-xs text-white md:text-sm">
            <code>
              {toH(
                React.createElement,
                refractor.highlight(selection.code, 'solidity')
              )}
            </code>
          </pre>
        </div>
      </div>
      <EditorBottom />
    </>
  )
}
