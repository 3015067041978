import { CancelCapsuleIcon } from '../Icons'

const cancelPackage = {
  menuName: 'Cancel Package',
  title: 'Cancel Package',
  description: 'Cancel any package in transit and retrieve the transferred assets.',
  icon: (props: any) => <CancelCapsuleIcon {...props} />,
  code: `// Solidity ^0.8.0

function cancelMyPackage(
  uint256 packageId_,
  address contentReceiver_
) external {
    // your code here...

    postOffice.cancelPackage(packageId_, contentReceiver_);
}`,
  gitBookLink:
    'https://docs.capsulenft.com/',
}

export default cancelPackage
