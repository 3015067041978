import { RerouteCapsuleIcon } from '../Icons'

const reroutePackage = {
  menuName: 'Reroute Package',
  title: 'Reroute Package',
  description: 'Reroute any package in transit to a new reciever.',
  icon: (props: any) => <RerouteCapsuleIcon {...props} />,
  code: `// Solidity ^0.8.0

function rerouteMyPackage(
  uint256 packageId_,
  address newReceiver_
) external {
    // your code here...
    
    postOffice.updatePackageReceiver(packageId_, newReceiver_);
}`,
  gitBookLink:
    'https://docs.capsulenft.com/',
}

export default reroutePackage
